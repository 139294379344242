@media (max-width: 1023px) {
  .burger {
    position: fixed;
    /* display: block; */
    top: 1rem;
    right: 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1.8rem;
    height: 1.8rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  }

  .burgerScrolled {
    position: fixed;
    /* display: block; */
    top: 1rem;
    right: 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1.8rem;
    height: 1.8rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  }
}
@media (min-width: 1024px) {
  .burger {
    position: fixed;
    /* display: block; */
    top: 2rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  }

  .burgerScrolled {
    position: fixed;
    top: 2rem;
    right: 2rem;
    animation: scrolledBurger 300ms linear 0s 1 forwards;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  }

  @keyframes scrolledBurger {
    100% {
      top: 0.8rem;
      right: 0.8rem;
    }
  }
}

.burger:focus {
  outline: none;
}

.burgerBarClose {
  /* width: 5rem; */
  height: 0.25rem;
  background: #5e503f;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.burgerBarOpen {
  width: 2rem;
  height: 0.25rem;
  background: white;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.burgerBarOpenScrolled {
  width: 2rem;
  height: 0.25rem;
  background: white;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.burgerBarClose:first-child {
  transform: rotate(45deg);
}

.burgerBarOpen:first-child {
  transform: rotate(0);
}

.burgerBarClose:nth-child(2) {
  opacity: 0;
  transform: translateX(20px);
}

.burgerBarOpen:nth-child(2) {
  opacity: 1;
  transform: translateX(0);
}

.burgerBarClose:nth-child(3) {
  transform: rotate(-45deg);
}

.burgerBarOpen:nth-child(3) {
  transform: rotate(0);
}
