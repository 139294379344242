/* Mobile Styles */
@media (max-width: 1023px) {
  .gallery-welcome-container {
    background-image: url(../../assets/photos/gifts.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
  }

  .gallery-welcome-text {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 7rem;
    height: 40vh;
  }

  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 15px;
    padding: 20px;
    justify-items: center;
  }

  .gallery-thumbnail-container {
    width: 140px;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    position: relative;
  }

  .gallery-photo {
    width: 100%;
    height: 140px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }

  /* Restore Hover Effect */
  .gallery-photo:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  .gallery-title-container {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gallery-title {
    font-size: 14px;
    color: #333;
    text-transform: capitalize;
    font-weight: bold;
    text-align: center;
    margin: 0;
  }

  .down-arrow {
    display: none;
  }
}

/* Desktop Styles */
@media (min-width: 1024px) {
  .gallery-welcome-container {
    background-image: url(../../assets/photos/gifts.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.45);
  }

  .gallery-welcome-text {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding-bottom: 17rem;
  }

  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 20px;
    padding: 30px;
    justify-items: center;
  }

  .gallery-thumbnail-container {
    width: 180px;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .gallery-photo {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }

  /* Restore Hover Effect */
  .gallery-photo:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  .gallery-title-container {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gallery-title {
    font-size: 14px;
    color: #333;
    text-transform: capitalize;
    font-weight: bold;
    text-align: center;
    margin: 0;
  }

  .down-arrow {
    position: absolute;
    bottom: 10%;
    font-size: 1.25rem;
    animation: animatedDownArrow 2s ease-in-out infinite;
  }
}

/* Common Styles */
.gallery-intro-text {
  text-align: justify;
  font-size: 16px;
  color: #555;
  margin-bottom: 15px;
  font-style: italic;
  padding-left: 10px;
}

.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.3s ease-in-out;
}

.gallery-modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  max-width: 80%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery-modal-img {
  max-width: 100%;
  max-height: 500px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.gallery-description {
  font-size: 16px;
  color: #555;
  margin-top: 10px;
  font-weight: bold;
}

/* Navigation */
.gallery-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 35px;
  cursor: pointer;
  color: white;
  transition: color 0.3s ease-in-out;
  padding: 10px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
}

.gallery-arrow:hover {
  background: rgba(0, 0, 0, 0.8);
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}

/* Close Button */
.gallery-close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 50%;
  transition: background 0.3s ease-in-out;
}

.gallery-close:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
