@media (max-width: 1023px) {
  .confirmation-welcome-container {
    background-image: url(../../assets/photos/confirmation_mobile.jpg);
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
    /* background-position: 0rem -3rem; */
  }

  .confirmation-welcome-text {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 7rem;
    height: 40vh;
  }

  .formStyle {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .confirmation-welcome-container {
    background-image: url(../../assets/photos/confirmation.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.45);
    /* background-position: 0rem 0rem; */
  }

  .confirmation-welcome-text {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding-bottom: 17rem;
  }

  .formStyle {
    width: 60%;
  }

  .downArrow {
    position: absolute;
    bottom: 3rem;
    font-size: 1.75rem;
    animation: animatedDownArrow 2s ease-in-out infinite;
  }
}

.formSection {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
}

.textAreaStyle {
  height: 5rem;
  width: 100%;
}

.manageInviteButtonStyle {
  width: 3rem;
  margin-bottom: 1rem;
  align-self: flex-end;
}

.manageInviteButtons > * + * {
  margin-left: 1rem;
}

@keyframes dot-keyframes {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }
  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}
.loadingDots {
  text-align: center;
  width: 100%;
}

.loadingDot {
  animation: dot-keyframes 1.5s infinite ease-in-out;
  background-color: white;
  border-radius: 10px;
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  margin-inline: 0.1rem;
}

.loadingDot:nth-child(2) {
  animation-delay: 0.5s;
}

.loadingDot:nth-child(3) {
  animation-delay: 1s;
}

.submit-button {
  margin-top: 0.5rem;
  min-width: 7rem !important;
}