.monogram {
  height: 60%;
  width: auto;
  margin: 1rem
}

@media (max-width: 1023px) {
  .monogram {
    margin-left: 0.5rem;
    margin-top: 1.05rem;
  }

  .outerContainer {
    height: 4rem;
    width: 100%;
    position: fixed;
    z-index: 3;
  }

  .outerContainerScrolled {
    height: 4rem;
    width: 100%;
    position: fixed;
    z-index: 3;
    animation: scrolledNavbar 300ms ease-in 0s 1 forwards;
  }

  @keyframes scrolledNavbar {
    100% {
      background: rgba(167, 167, 167, 0.7);
    }
  }
}

@media (min-width: 1024px) {
  .outerContainer {
    height: 6rem;
    width: 100%;
    position: fixed;
    z-index: 3;
  }

  .outerContainerScrolled {
    height: 6rem;
    width: 100%;
    position: fixed;
    z-index: 10;
    animation: scrolledNavbar 200ms ease-in 0s 1 forwards;
  }

  @keyframes scrolledNavbar {
    100% {
      height: 3.5rem;
      background: rgba(167, 167, 167, 0.3);
    }
  }
}
