@media (max-width: 1023px) {
  .gifts-welcome-container {
    background-image: url(../../assets/photos/gifts_mobile.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
    /* background-position: -10rem -25rem; */
  }

  .gifts-welcome-container-android {
    background-size: cover !important;
    background-position: center center !important;
  }

  .gifts-welcome-text {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .downArrow {
    position: absolute;
    bottom: 3rem;
    font-size: 2rem;
    animation: animatedDownArrow 2s ease-in-out infinite;
  }

  .downArrow {
    position: absolute;
    bottom: 3rem;
    font-size: 1.25rem;
    animation: animatedDownArrow 2s ease-in-out infinite;
  }

  .normalFontStyle {
    font-size: 1rem;
  }

  .giftsTextbox {
    margin: 0.5rem;
    padding: 0.5rem;
  }
}

@media (min-width: 1024px) {
  .gifts-welcome-container {
    background-image: url(../../assets/photos/gifts.jpg);
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  }

  .gifts-welcome-text {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding-bottom: 12rem;
  }

  .downArrow {
    position: absolute;
    bottom: 3rem;
    font-size: 1.75rem;
    animation: animatedDownArrow 2s ease-in-out infinite;
  }

  .giftsTextbox {
    margin: 1.5rem;
    padding: 1.5rem;
  }
}

@keyframes animatedDownArrow {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}