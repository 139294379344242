body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "SilentReaction";
  src: local("SilentReaction"),
    url("./fonts/SilentReaction.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "AlexBrushRegular";
  src: local("AlexBrushRegular"),
    url("./fonts/AlexBrush-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "CrimsomRegular";
  src: local("CrimsomRegular"),
    url("./fonts/CrimsonText-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "CrimsomItalix";
  src: local("CrimsomItalix"),
    url("./fonts/CrimsonText-Italic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "HoneyScriptLight";
  src: local("HoneyScriptLight"),
    url("./fonts/HoneyScript-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "CormorantGaramondRegular";
  src: local("CormorantGaramondRegular"),
    url("./fonts/CormorantGaramond-Regular.ttf") format("truetype");
  font-weight: normal;
}
