.informationContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  position: relative;
  margin: 4rem;
}

.informationContainer > * + * {
  margin-top: 1em;
}

.inviteContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  margin: 4rem;
  /* height: 100vh; */
}

.inviteContainer > * + * {
  margin-top: 1em;
}

@media (min-width: 1024px) {
  .mobileScreenInvite {
    display: none;
  }

  .welcomeInviteContainer {
    background-image: url(../../assets/photos/invite.jpg);
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 100% 0%;
    animation: animatedInviteBackground 5s ease-in forwards;
    background-repeat: no-repeat;
    background-attachment: fixed;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
  }

  .inviteInfoContainer {
    display: flex;
    text-align: center;
    margin: 2em;
  }

  .welcomeInviteText {
    /* color: rgb(248, 248, 248); */
    color: rgb(240, 240, 240);
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-end;
    margin-left: 70%;
    padding-bottom: 2%;
    width: 25%;
    background: rgba(177, 177, 177, 0.231);
    text-align: justify;
    text-justify: inter-word;
    /* opacity: 0.8; */
  }

  .welcomeInviteText > div > p {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }

  .welcomeInviteText > div > p > a {
    color: white !important;
  }

  .inviteLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 2rem; */
    /* margin-bottom: 5rem; */
    width: 30%;
    height: auto;
  }

  .downArrow {
    display: none;
  }
  
}

@media (max-width: 1023px) {
  .mobileScreenInvite {
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .welcomeInviteContainer {
    background-image: url(../../assets/photos/invite_mobile.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
  }

  .inviteWelcomeText {
    color: white;
    padding-bottom: 15rem;
  }

  .laptopScreenInvite {
    display: none;
  }

  .inviteLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 2rem; */
    /* margin-bottom: 4rem; */
    width: 80%;
    height: auto;
  }

  .inviteInfoContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-evenly;
    margin: 2em;
  }

  .inviteInfoContainer > * + * {
    margin-top: 2em;
  }

  .welcomeInviteText {
    color: rgb(240, 240, 240);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100vh;
    padding-top: 7rem;
    justify-content: center;
    align-items: center;
  }

  .downArrow {
    position: absolute;
    bottom: 3rem;
    font-size: 1.25rem;
    animation: animatedDownArrow 2s ease-in-out infinite;
  }
}

.invite-church-image-style {
  width: auto;
  height: 240px;
  border-radius: 1.5%;
}

.icons-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin-top: 3rem;
}

.inviteIconStyle {
  font-size: 2rem;
  margin-bottom: 1rem;
}

@keyframes animatedDownArrow {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes animatedInviteBackground {
  100% {
    background-position: center center;
  }
}
