.welcomeHomeContainer {
  background-image: url(../../assets/photos/home_page.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 100% 0%;
  animation: animatedBackground 5s ease-in forwards;
  background-repeat: no-repeat;
  background-attachment: fixed;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  /* filter: grayscale(90%); */
  height: 100vh;
}

.welcomeImageContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.welcomeHomeText {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-top: 15rem;
  padding-left: 3rem;
  visibility: hidden;
  opacity: 0;
  animation: showWelcomeText 2s ease-in 500ms 1 forwards;
}

.homeContainerStyle {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

section + * > * {
  margin-top: 1em;
}

.countdownText {
  white-space: no-wrap;
  font-size: 55px;
  font-weight: 300;
  -webkit-line-clamp: 32px;
  /* color: #5e503f; */
}

.countdownHeaderText {
  white-space: no-wrap;
  font-size: 70px;
  font-weight: 300;
  -webkit-line-clamp: 32px;
  /* color: #5e503f; */
}

.home-section-welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.home-section-welcome > * + * {
  margin-left: 2em;
}

.home-section-column {
  display: grid;
}

.imageGridStyle {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  margin-bottom: 1em;
}

.rspv-button {
  margin-top: 2em;
}

.home-house-image-style {
  height: auto;
  width: 400px;
  border-radius: 1.5%;
}

.saveTheDateText {
  font-family: CormorantGaramondRegular;
  letter-spacing: 0.4em;
  font-size: 250%;
}

.dateText {
  font-family: CormorantGaramondRegular;
  font-size: 120%;
  letter-spacing: 0.4em;
}

/* Mobile */
@media (max-width: 1023px) { 
  /* .welcomeHomeContainer {
    background-image: url(../../assets/photos/home_page_mobile.jpg);
    /* background-position: 0 -0.1rem;
  } */

  .welcomeHomeContainer {
    background-image: url(../../assets/photos/home_page_mobile.jpg);
    background-size: 130%; /* Zoom in a bit to allow for movement */
    /* background-repeat: no-repeat; */
    background-position: center;
    background-repeat: no-repeat;
    animation: animatedBackground 5s ease-in-out 1 forwards; /* Adjust the timing as needed */
  }

  .countdownText {
    font-size: 20px;
  }

  .countdownHeaderText {
    font-size: 50px;
  }

  .welcomeHomeText {
    padding-top: 5rem;
    padding-left: 0;
    animation: showWelcomeText 3s ease-in 500ms 1 forwards;
  }

  .home-section-welcome {
    flex-direction: column;
  }

  .home-section-welcome > * + * {
    margin-top: 1em;
  }

  .home-section-column {
    display: grid;
  }

  .home-house-image-style {
    height: auto;
    width: 100%;
    border-radius: 2%;
  }

  .saveTheDateText {
    font-size: 150%;
  }

  .dateText {
    letter-spacing: 0.1em;
  }
}

@keyframes showWelcomeText {
  100% {
    visibility: visible;
    opacity: 1;
  }
}
/* 
@keyframes animatedBackground {
  100% {
    background-position: center center;
  }
} */

@keyframes animatedBackground {
  0% {
    background-position: center 20%; /* Start at the top */
  }
  100% {
    background-position: center center; /* End at the center */
  }
}