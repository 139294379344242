.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.color-primary {
  color: #5e503f;
}

.color-primary-light {
  color: #dcac84;
}

.color-secondary {
  color: #fbfbf2;
}

.centered {
  text-align: center;
}

@media (max-width: 1023px) {
  .headerText {
    white-space: no-wrap;
    /* font-size: 35px; */
    font-weight: 300;
    -webkit-line-clamp: 32px;
    /* color: #5e503f; */
  }

  .homeHeaderText {
    white-space: no-wrap;
    /* font-size: 35px; */
    font-weight: 300;
    -webkit-line-clamp: 32px;
    /* color: #5e503f; */
  }
}

@media (min-width: 1024px) {
  .headerText {
    white-space: no-wrap;
    font-size: 35px;
    font-weight: 300;
    -webkit-line-clamp: 32px;
    /* color: #5e503f; */
  }

  .homeHeaderText {
    white-space: no-wrap;
    font-size: 35px;
    font-weight: 300;
    -webkit-line-clamp: 32px;
    /* color: #5e503f; */
  }
}

.subHeaderFontStyle {
  white-space: no-wrap;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
}

.normalFontStyle {
  white-space: no-wrap;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 30px;
  text-align: justify;
}

.normalFontStyle2 {
  white-space: no-wrap;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 30px;;
}

.commentFontStyle {
  white-space: no-wrap;
  font-size: 15px;
  font-weight: 300;
  line-height: 32px;
}

h4 {
  white-space: no-wrap;
  font-size: 35px;
  font-weight: 300;
  -webkit-line-clamp: 32px;
}

h5 {
  white-space: no-wrap;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
}
