.burgerMenuClosed {
  display: flex;
  flex-direction: column;
  background: #fbfbf2;
  min-height: 100%;
  width: 18rem;
  padding: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
  position: fixed;
  z-index: 2;
  opacity: 1 !important;
}

.burgerMenuOpen {
  display: flex;
  flex-direction: column;
  background: #fbfbf2;
  /* min-height: 100vh; */
  min-height: 100%;
  width: 18rem;
  padding: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out;
  position: fixed;
  z-index: 3;
  opacity: 1 !important;
}

.menuItem {
  color: #5e503f;
  margin-top: 1.5rem;
  z-index: 10;
  transition: font-size 200ms;
}

.menuItem:hover {
  text-decoration: underline;
  font-size: 20px;
}

/* New styles for logo positioning */
.logoContainer {
  margin-top: auto; /* Pushes the logo to the bottom */
  text-align: center; /* Center the logo horizontally */
  padding: 1rem 0; /* Optional padding */
}

.logo {
  width: 90%; /* Adjust size as needed */
  height: auto;
}

/* Add this for the language switcher */
.languageSwitcher {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.languageSwitcher button {
  background-color: transparent;
  border: none;
  color: #5e503f;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  margin: 0 0.5rem;
  transition: color 0.3s;
}

.languageSwitcher button:hover {
  text-decoration: underline;
}
