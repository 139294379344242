.HotelContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  position: relative;
}

.HotelImage {
  position: relative;
  border-radius: 10px;
}

.Stay_Welcome_Container {
  background-image: url(../../assets/photos/home_page_mobile.jpg);
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* background-position: center center; */
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.distance {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  white-space: no-wrap;
  font-size: 1.5rem;
  padding-top: 1rem;
  /* font-weight: 300; */
  /* line-height: 30px; */
}

@media (min-width: 1024px) {
  .Stay_Welcome_Text {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30vh;
    padding-top: 3rem;
  }

  .headerText {
    white-space: no-wrap;
    font-size: 35px;
    font-weight: 300;
    -webkit-line-clamp: 32px;
  }

  .stayTextbox {
    margin: 1.5rem;
    padding: 1.5rem;
  }

  .HotelContainer {
    display: flex;
    text-align: center;
    flex-direction: row;
  }

  .HotelImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
  }

  .downArrow {
    display: none;
  }
}

@media (max-width: 1023px) {
  /* .Stay_Welcome_Container {
    background-image: url(../../assets/photos/home_page_mobile.jpg);
  } */

  .stay-welcome-container-android {
    background-size: cover !important;
    background-position: center center !important;
  }

  .Stay_Welcome_Text {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25vh;
    padding-top: 3rem;
  }

  .headerText {
    white-space: no-wrap;
    font-weight: 300;
    -webkit-line-clamp: 32px;
  }

  .stayTextbox {
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .HotelContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-evenly;
    margin-left: 2em;
    margin-right: 2em;
    /* margin: 2em; */  
  }

  .HotelImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
  }

  .downArrow {
    position: absolute;
    margin-top: 4em;
    font-size: 1.25rem;
    animation: animatedDownArrow 2s ease-in-out infinite;
  }

}

@keyframes animatedDownArrow {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}